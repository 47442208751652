<template>
    <div class="yj-conten" style="background-color:#fff;width:calc(100% - 32px); min-width:fit-content">
        <a-layout-content style="padding:0;">
            <div class="yj-conten " style="background: #fff;padding: 20px 20px 0;margin: 0;">
                <a-row class="yj-form">
                    <a-col :span="24">
                        <label class="yj-form_label">姓名 :</label>
                        <a-input id="name"
                                 contextmenu="2455"
                                 :value="name"
                                 style="width:260px"
                                 @change="name_change"
                                 placeholder="姓名"></a-input>
                        <a-button type="primary" @click="search">查询</a-button>
                    </a-col>
                </a-row>
                <a-table :columns="columns" bordered
                         rowKey="ID"
                         :data-source="tableData"
                         :pagination="pagination"
                         @change="pagerChange"
                         style=" min-height: calc(100vh - 234px);min-width: max-content"
                         class="yj-table-img">
                    <span slot="User" slot-scope="text, record">
                        <a @click="jump(GetUser(record,0,2))" style="cursor:pointer;"><img :src="GetUser(record,0,0)" width="50" height="50" style="float: left;margin-right: 16px;" /><strong class='goods-name' style="line-height:50px;">{{GetUser(record,0,1) }}</strong></a>
                    </span>
                    <div slot="Service" slot-scope="text, record">
                        <div v-if="GetUser(record,2,2)!=null">
                            <a @click="PartnerDetail(GetUser(record,2,2))" style="cursor:pointer;"><img :src="GetUser(record,2,0)" width="50" height="50" style="float: left;margin-right: 16px;" /><strong class='goods-name' style="line-height:50px;">{{GetUser(record,2,1) }}</strong></a>
                        </div>
                    </div>
                    <div slot="Partner" slot-scope="text, record">
                        <div v-if="GetUser(record,1,2)!=null">
                            <a @click="PartnerDetail(GetUser(record,1,2))" style="cursor:pointer;"><img :src="GetUser(record,1,0)" width="50" height="50" style="float: left;margin-right: 16px;" /><strong class='goods-name' style="line-height:50px;">{{GetUser(record,1,1) }}</strong></a>
                        </div>
                    </div>
                    <span slot="action" slot-scope="text, record">
                        <a :href=record.Url target="_blank">对话详情</a>
                    </span>
                </a-table>
                <a-modal v-model="QRCode" title="微信扫码预览" :afterClose='clearInterval'>
                    <div style="text-align:center;">
                        <a download="微信扫码预览" :href="QRCodeURL" target="_blank">
                            <img :src="QRCodeURL" height="360" />
                        </a>
                    </div>
                    <div slot="footer"></div>
                </a-modal>
            </div>
        </a-layout-content>
    </div>
</template>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    export default {
        name: "ChatWebInfor_Detail",
        data() {
            return {
                columns: [
                    {
                        title: "客户",
                        width: 230,
                        scopedSlots: { customRender: 'User' }
                    },
                    {
                        title: "客服",
                        width: 230,
                        scopedSlots: { customRender: 'Service' }
                    },
                    {
                        title: "合伙人",
                        width: 230,
                        scopedSlots: { customRender: 'Partner' }
                    },
                    {
                        title: "消息数量",
                        dataIndex: "ContentCount",
                        align: "center",
                        width: 230
                    },
                    {
                        title: "未读数量",
                        dataIndex: "NotReadCount",
                        align: "center",
                        width: 230
                    },
                    {
                        title: "最新时间",
                        align: "center",
                        width: 230,
                        customRender: (text, item) => {
                            return util.TimeStamptoDateTime(item.DateTime, 'yyyy-MM-dd hh:mm:ss')
                        }
                    },
                    {
                        title: '操作',
                        align: "center",
                        width: 230,
                        scopedSlots: { customRender: 'action' }
                    }
                ],
                tableData: [],
                name: "",
                pagination: {
                    total: 0,
                    defaultPageSize: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                },
                IntervalTimer: null,
                LongTimer: null,
                QRCode: false,
                QRCodeID: 0,
                QRCodeURL: '',
                id: ""
            };
        },
        props: {//组件属性
        },
        methods: {//方法
            name_change: function (item) {
                var self = this;
                self.name = item.target.value;
            },
            search: function () {
                var self = this;
                self.pagination.current = 1;
                self.getTableData();
            },
            getTableData: function () {
                var self = this;
                var op = {
                    url: "/OperationsModule/Operations/ChatServerList",
                    data: {
                        pageIndex: self.pagination.current,
                        name: self.name,
                        UserID: self.id
                    },
                    OnSuccess: function (data) {
                        self.tableData = data.data.Data;
                        self.pagination.total = data.data.Total;
                        self.pagination.defaultPageSize = data.data.RowCount;
                        self.pagination.current = data.data.PageIndex;
                    }
                };
                http.Post(op);
            },
            pagerChange: function (item) {
                var self = this;
                self.pagination.current = item.current;
                self.getTableData();
            },
            DetailRow(e) {
                var self = this;
                var UserID = self.getCookie('UserID');
                if (UserID == "") {
                    self.AddQRCodeID();
                    self.QRCode = true;
                    self.IntervalTimer = setInterval(function () {
                        self.GetUserID(e.Url, e.GroupID);
                    }, 5000);
                    self.LongTimer = setInterval(function () {
                        self.clearInterval();
                        self.$info({
                            title: '提示',
                            content: '登录超时，请重新登录！',
                            okText: "确定"
                        });
                    }, 300000);
                } else {
                    self.Success(e.Url, UserID);
                }
            },
            GetUserID(e, d) {
                var self = this;
                var op = {
                    url: "/OperationsModule/Operations/GetUserID",
                    data: {
                        GroupID: d,
                        ID: self.QRCodeID
                    },
                    OnSuccess: function (data) {
                        if (data.data.State == 1) {
                            self.Success(e, data.data.UserID)
                        }
                        if (data.data.State == -1) {
                            self.clearInterval();
                            self.$info({
                                title: '提示',
                                content: data.data.Message,
                                okText: "确定"
                            });
                        }
                    }
                };
                http.Post(op);
            },
            Success(e, d) {
                var self = this;
                self.setCookie('UserID', d, 20);
                self.clearInterval();
                var a = document.createElement("a");
                a.setAttribute("href", e + d);
                a.setAttribute("target", "_blank");
                a.click();
            },
            clearInterval() {
                var self = this;
                window.clearInterval(self.IntervalTimer);
                window.clearInterval(self.LongTimer);
                self.QRCode = false;
            },
            AddQRCodeID() {
                var self = this;
                var op = {
                    url: "/OperationsModule/Operations/AddQRCodeID",
                    data: {
                    },
                    OnSuccess: function (data) {
                        self.QRCodeID = data.data;
                        self.GetQRCodeUrl();
                    }
                };
                http.Post(op);
            },
            GetQRCodeUrl() {
                var self = this;
                var op = {
                    url: "/OperationsModule/Operations/GetQRCodeUrl",
                    data: {
                        ID: self.QRCodeID
                    },
                    OnSuccess: function (data) {
                        self.QRCodeURL = data.data;
                    }
                };
                http.Post(op);
            },
            GetUser(info, RoleID, Types) {
                var rt;
                info.Role.forEach(e => {
                    if (e.RoleID == RoleID) {
                        switch (Types) {
                            case 0:
                                rt = e.HeadImg
                                break;
                            case 1:
                                rt = e.Name
                                break;
                            case 2:
                                rt = e.UserID
                                break;
                        }
                    }
                });
                return rt;
            },
            jump: function (data) {
                var self = this;
                self.$router.push({
                    name: "OperationsDetail", query: {
                        ID: data
                    }
                })
            },
            PartnerDetail(e) {
                this.$router.push({
                    name: "PartnerList_PartnerDetail",
                    query: { ID: e }
                });
            },
            setCookie: function (cname, cvalue, exdays) {
                var d = new Date();
                d.setTime(d.getTime() + (exdays * 60 * 1000));
                var expires = "expires=" + d.toUTCString();
                document.cookie = cname + "=" + cvalue + "; " + expires;
            },
            getCookie: function (cname) {
                var name = cname + "=";
                var ca = document.cookie.split(';');
                for (var i = 0; i < ca.length; i++) {
                    var c = ca[i];
                    while (c.charAt(0) == ' ') c = c.substring(1);
                    if (c.indexOf(name) != -1) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            }
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
            this.id = this.$route.query.ID;
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
            this.getTableData();
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>